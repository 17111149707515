import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.scss";
import Supensefallback from "./shared/components/Supensefallback/Supensefallback";
import CreateFertigationPlan from "./container/CreateFertigationPlan/CreateFertigationPlan.lazy";
import RequireAuth from "./shared/components/RequireAuth/RequireAuth.lazy";
import Landing from "./container/Landing/Landing.lazy";
import Header from "./shared/components/Header/Header.lazy";
import FertigationPlanView from "./container/FertigationPlanView/FertigationPlanView.lazy";
import EditFertigationPlan from "./container/EditFertigationPlan/EditFertigationPlan.lazy";
import CheckCreateFertigation from "./shared/components/CheckCreateFertigation/CheckCreateFertigation.lazy";
import analyticsService from "./shared/services/AnalyticsService";
// import { useAuth0 } from "@auth0/auth0-react";
import Footer from "./shared/components/Footer/Footer";
import Sidebar from "./shared/components/Sidebar/Sidebar";
import FertigationSpecification from "./container/FertigationSpecification/FertigationSpecification";
import ErrorBoundary from "./ErroBoundary";
import { ProtectedRoute } from "./shared/Auth0ProtectRoute";
import UserService from "./shared/services/UserService";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { logout, user, isLoading } = useAuth0();
  const segmentId = process.env.REACT_APP_SEGMENT_KEY;
  const [token, setToken] = React.useState("");
  const [userDetails, setUserDetails]: any = React.useState({});

  React.useEffect(() => {
    if (window.analytics?.load) {
      analyticsService.load(segmentId);
    }
  }, [segmentId]);

  React.useEffect(() => {
    let timer = setTimeout(() => {
      setToken(sessionStorage.getItem("acess_token") ?? "");
      if (token && token !== "") {
        clearTimeout(timer);
      }
    }, 3000);
    // if(tokens)
  }, []);

  React.useEffect(() => {
    if (token) {
      let name: any = user?.name;
      UserService.getUserByName(name, "id firstName lastName created").then(
        async (data) => {
          // let user_id: any = data?.id;
          // console.log(data);
          // console.log("DATA");
          setUserDetails({
            firstName: data?.firstName,
            lastName: data?.lastName,
            created: data?.created,
          });
        }
      );
    } else {
      setUserDetails({
        firstName: "",
        lastName: "",
      });
    }
  }, [token, user]);
  const props={
    setUserDetails
  }

  return (
    <div className="App row p-0 m-0 pos-relative" data-testid="appComponent">
      <div className="col-md-1 col-2 p-0 m-0 fixFromTop fullHeight">
        <Routes>
          <Route path="*" element={<ProtectedRoute component={Sidebar}></ProtectedRoute>}></Route>
        </Routes>
      </div>
      <div className="col-10 col-md-11 p-0 m-0">
        <div>
          <Routes>
            <Route path="*" element={
            <ProtectedRoute component={Header} {...userDetails}>

            </ProtectedRoute>}>

            </Route>
          </Routes>
        </div>
        <div>
          <Suspense fallback={<Supensefallback></Supensefallback>}>
            <Routes>
              <Route path="/landing" element={<Landing/>}>
                <Route path=":signup" element={<Landing/>}>
                  <Route path="" element={<ProtectedRoute component={Landing}></ProtectedRoute>}></Route>
                </Route>
              </Route>
              <Route element={<RequireAuth />}>
                <Route element={<ErrorBoundary><CheckCreateFertigation /></ErrorBoundary>}>
                  <Route
                    path="/"
                    element={<FertigationPlanView />}
                  ></Route>
                  <Route
                    path="/createfertigationplan"
                    element={<CreateFertigationPlan setUserdetails={setUserDetails} {...props}/>}
                  ></Route>
                  <Route
                    path="/editfertigationplan"
                    element={<EditFertigationPlan />}
                  ></Route>
                  <Route
                    path="/viewfertigaionplan"
                    element={<FertigationPlanView />}
                  ></Route>
                </Route>
                <Route
                  path="/fertigationspecification"
                  element={<FertigationSpecification />}
                ></Route>
              </Route>
              <Route path="*" element={<Navigate to={"/landing"} />} />
            </Routes>
          </Suspense>
        </div>
        <div>
          
          <Routes>
            <Route path="*" element={<ProtectedRoute component={Footer}></ProtectedRoute>}></Route>
          </Routes>
         
        </div>
      </div>
    </div>
  );
}

export default App;
