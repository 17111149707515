import React, { lazy, Suspense } from 'react';

const LazyFertigationPlanView = lazy(() => import('./FertigationPlanView'));

const FertigationPlanView = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyFertigationPlanView {...props} />
  </Suspense>
);

export default FertigationPlanView;
