import React from "react";
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import { BrowserRouter as Router, Route, useNavigate } from "react-router-dom";
// const args = {
//     onRedirecting: () => (<div>Redirecting you to the login page...</div>)
//   }

export const ProtectedRoute = ({ component, ...args }) => {
    const Component = withAuthenticationRequired(component, args);
    return <Component {...args}/>;
  };
  
export const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
    const navigate = useNavigate();
    const onRedirectCallback = (appState?) => {
      navigate((appState && appState.returnTo) || window.location.pathname);
    };
    return (
      <Auth0Provider onRedirectCallback={onRedirectCallback} 
      domain={props.domain} clientId={props.clientId} {...props}>
        {children}
      </Auth0Provider>
    );
  };
  