import http from "./HttpCommon";
import User from "../models/User";
import UserInput from "../models/UserInput";
// import UserUpdateInput from "../models/UserUpdateInput";
// import UserCropInput from "../models/UserCropInput";
import GenericFilter from "../models/GenericFilter";
// import { getIdTokenClaimsOptions } from "@auth0/auth0-spa-js";

const getAll = () => {
  return http.get<Array<User>>("/tutorials");
};
const get = (id: any) => {
  return http.get<User>(`/tutorials/${id}`);
};
const getUserByName = (name: string, includeOutquery: string) => {
  // console.log(name);
  const getUserbyNameQuery = `query{userDetailByAuthName(name: "${name}"){${includeOutquery}}}`;

  return http
    .request<User>({
      url: "graphql",
      method: "POST",
      data: { query: getUserbyNameQuery },
    })
    .then((response) => response.data)
    .catch((error) => {
      // console.log(error);
      return null;
    });
};
const create = (data: UserInput, includeOutquery: string) => {
  const getUserbyNameQuery = `
    mutation{
        createUser(userinput: {
        firstName: "${data.firstName}"
        lastName: "${data.lastName}"
        email: "${data.email}"
        nameAuth: "${data.nameAuth}"
        companyName: "${data.companyName}"
        }){
       ${includeOutquery}
        }
        }`;

  return http
    .request<User>({
      url: "graphql",
      method: "POST",
      data: { query: getUserbyNameQuery },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      return null;
    });
};

const filterUsers = (filters: GenericFilter[], includeOutquery: string) => {
  let filtersStr: string = "";
  if (filters?.length > 0) {
    filtersStr = filters
      .map(
        (obj: GenericFilter) =>
          `{
      key: "${obj.key}"
      value: "${obj.value}"
      type: ${obj.type}
      }`
      )
      .join("");
  }

  const getFieldByNameQuery = `
  query{
    getUsers(
    filter: [${filtersStr}] 
    )
    {
      entities{ ${includeOutquery}}
    }
  
  }`;

  return http
    .request<User>({
      url: "graphql",
      method: "POST",
      data: { query: getFieldByNameQuery },
    })
    .then((response) => response.data)
    .catch((error) => {
      // console.log(error);
      return null;
    });
};

const login = (user: any) => {
  return http
    .request<any>({
      url: "login",
      method: "POST",
      data: user,
    })
    .then((reponse) => {
      return reponse.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
};

const update = (id: any, data: User) => {
  return http.put<any>(`/tutorials/${id}`, data);
};
const remove = (id: any) => {
  return http.delete<any>(`/tutorials/${id}`);
};
const removeAll = () => {
  return http.delete<any>(`/tutorials`);
};
const findByTitle = (title: string) => {
  return http.get<Array<User>>(`/tutorials?title=${title}`);
};

const UserService = {
  getAll,
  getUserByName,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  login,
  filterUsers,
};
export default UserService;
