import React, { lazy, Suspense } from "react";

const LazyCreateFertigationPlan = lazy(() => import("./CreateFertigationPlan"));

const CreateFertigationPlan = ( 
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode,setUserdetails:any }
) => (
  <Suspense fallback={null}>
    <LazyCreateFertigationPlan  {...props} />
  </Suspense>
);

export default CreateFertigationPlan;
