import React, { lazy, Suspense } from 'react';

const LazyCheckCreateFertigation = lazy(() => import('./CheckCreateFertigation'));

const CheckCreateFertigation = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyCheckCreateFertigation {...props} />
  </Suspense>
);

export default CheckCreateFertigation;
