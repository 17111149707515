declare global {
  interface Window {
    analytics: any;
  }
}

export const identify = (user?: any, userEmail?: any, userName?: any) => {
  window.analytics.identify(user.name, {
    nickName: user.name,
    // userId: user.sub,
    userEmail: userEmail,
    userName: userName,
  });
};

export const load = (segmentId: any) => {
  window.analytics.load(segmentId);
};

export const page = (
  name: string,
  channel: any,
  crop: any,
  cropId: any,
  region: any,
  regionId: any,
  country: any,
  countryId: any
) => {
  window.analytics.page(name, {
    ip: "none",
    channel: channel,
    crop: crop,
    cropId: cropId,
    region: region,
    regionId: regionId,
    country: country,
    countryId: countryId,
  });
};

export const track = (
  name: string,
  channel: any,
  crop: any,
  cropId: string,
  region: any,
  regionId: string,
  country: any,
  countryId: string
) => {
  window.analytics.track(name, {
    channel: channel,
    crop: crop,
    cropId: cropId,
    region: region,
    regionId: regionId,
    country: country,
    countryId: countryId,
  });
};

export const log = (name: string, segmentObj: Object) => {
  window.analytics.page(name, segmentObj);
};

export const checkBox = (name: string, channel: any, checked: boolean) => {
  window.analytics.track(name, {
    channel: channel,
    checked,
  });
};

const analyticsService = {
  identify,
  load,
  page,
  track,
  log,
  checkBox,
};

export default analyticsService;
