import React, { FC } from "react";
import styles from "./DataTable.module.scss";
import ConvertedTab from "../ConvertedTab/ConvertedTab";
import AddProcduct from "../../AddProcduct/AddProcduct";
// import FertigationSpecificationService from "../../../shared/services/FertigationSpecificationService";
import { FilterType } from "../../../shared/models/Enums";

interface DataTableProps {
  token?: any;
  totalRequiredNutrient?: any;
  setManualCorrectionNutrient?: any;
  tableHeader?: any;
  getTabelHeader?: any;
  getPercentage?: any;
  getEmptyifZero?: any;
  roundToTwo?: any;
  fertigationSpec?: any;
  setFertigationSpec?: any;
  totalInputKg?: any;
  setTotalInputKg?: any;
  setCheckedFertilizer?: any;
  setcheckedNutrients?: any;
  setTotalOutputNutrient?: any;
  setSolverResult?: any;
  enableCalButton?: any;
  initialNutrientCheckData?:any
}

const DataTable: FC<DataTableProps> = ({
  token,
  totalRequiredNutrient,
  setManualCorrectionNutrient,
  tableHeader,
  getTabelHeader,
  getPercentage,
  getEmptyifZero,
  roundToTwo,
  fertigationSpec,
  setFertigationSpec,
  totalInputKg,
  setTotalInputKg,
  setCheckedFertilizer,
  setcheckedNutrients,
  setTotalOutputNutrient,
  setSolverResult,
  enableCalButton,
  initialNutrientCheckData,
}) => {
  let inputPattern = "^([0-9]+.?[0-9]{0,2}|.[0-9]+)$";
  let inputNegativePattern = "^(-?+[0-9]+.?[0-9]{0,2}|.[0-9]+)$";

  const handleInputchange = (e) => {
    const { name, value } = e.target;
    setTotalInputKg({
      ...totalInputKg,
      [name]: Number(value),
    });
    enableCalButton(false);
    setCheckedFertilizer({});
    setcheckedNutrients(initialNutrientCheckData);
    setTotalOutputNutrient({
      N: 0,
      P2O5: 0,
      K2O: 0,
      CaO: 0,
      MgO: 0,
      SO3: 0,
      Cl: 0,
      sum: 0,
    });
    setSolverResult({});
    console.log("Regex is valid ", e.target.validity.valid);
    if (e.target.validity.valid) {
      let objFertigation = fertigationSpec.find((obj) => obj.name == name);

      if (objFertigation) {
        objFertigation.name_i = value;
        if (!isNaN(value)) {
          for (const key of tableHeader) {
            if (objFertigation[key] !== 0) {
              totalRequiredNutrient.addedUpNutrient[key] -=
                objFertigation["t" + key] ?? 0;
              objFertigation["t" + key] = getPercentage(
                Number(value),
                Number(objFertigation[key])
              );
              totalRequiredNutrient.addedUpNutrient[key] +=
                objFertigation["t" + key] ?? 0;
              totalRequiredNutrient.addedUpNutrient[key] = roundToTwo(
                totalRequiredNutrient.addedUpNutrient[key] ?? 0
              );
              if (
                totalRequiredNutrient.addedUpNutrient[key] <
                totalRequiredNutrient.manualCorrectionNutrient[key]
              ) {
                totalRequiredNutrient.manualCorrectionNutrient[key] = 0;
              }
            }
          }
        }
      }
      setFertigationSpec([...fertigationSpec]);
    }
  };
  const handleManualCorrectionInputchange = (e) => {
    let { name, value } = e.target;
    if (value == "") {
      value = 0;
    }
    if (e.target.validity.valid) {
      totalRequiredNutrient.manualCorrectionNutrient[name.replace("m_", "")] =
        value;
      setManualCorrectionNutrient({ ...totalRequiredNutrient });
    }
  };

  const [blur, setBlur] = React.useState(true);

  const obj: any = {
    handleBlur: handleChangeBlur,
    setFertigationSpec,
    fertigationSpec,
  };

  function handleChangeBlur(blur) {
    setBlur(blur);
  }

  const sumValues = (obj) =>
    Object.values(obj).reduce((a: any, b: any) => a + b);
  return (
    <>
      {/* {JSON.stringify(totalInputKg)} */}
      {fertigationSpec.length > 0 ? (
        <div className={styles.DataTable}>
          {blur ? "" : <div className="background-blur"></div>}
          <div className="table-responsive mt-md-3 mt-2">
            <table
              width="100%"
              className={styles._calTable + " table caption-top table-bordered"}
            >
              <thead>
                <tr>
                  <td scope="col text-center">
                    <strong>Producto</strong>
                    <div>
                      <small>Nombre</small>
                    </div>
                  </td>
                  <td scope="col text-center">
                    <strong>Cantidad</strong>
                    <div>
                      <small>Kg/ha</small>
                    </div>
                  </td>
                  <td scope="col">
                    <strong>Nutrientes</strong>
                    <table className={styles.headerTable1}>
                      {getTabelHeader("%")}
                    </table>
                  </td>
                  <td scope="col">
                    <strong>Cantidad Total</strong>
                    <table className={styles.headerTable2}>
                      {getTabelHeader()}
                    </table>
                  </td>
                </tr>
              </thead>
              <tbody>
                {fertigationSpec.length > 0 &&
                  fertigationSpec.map((data) => (
                    <>
                      <tr key={data.name}>
                        <td>{data.name}</td>
                        <td>
                          <input
                            type="number"
                            value={data["name_i"]}
                            pattern={inputPattern}
                            onChange={handleInputchange}
                            name={data.name}
                            min={0}
                          />
                        </td>
                        <td>
                          <table>
                            <tbody>
                              <tr>
                                <td>{getEmptyifZero(data[tableHeader[0]])}</td>
                                <td>{getEmptyifZero(data[tableHeader[1]])}</td>
                                <td>{getEmptyifZero(data[tableHeader[2]])}</td>
                                <td>{getEmptyifZero(data[tableHeader[3]])}</td>
                                <td>{getEmptyifZero(data[tableHeader[4]])}</td>
                                <td>{getEmptyifZero(data[tableHeader[5]])}</td>
                                <td>{getEmptyifZero(data[tableHeader[6]])}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  {getEmptyifZero(data["t" + tableHeader[0]])}
                                </td>
                                <td>
                                  {getEmptyifZero(data["t" + tableHeader[1]])}
                                </td>
                                <td>
                                  {getEmptyifZero(data["t" + tableHeader[2]])}
                                </td>
                                <td>
                                  {getEmptyifZero(data["t" + tableHeader[3]])}
                                </td>
                                <td>
                                  {getEmptyifZero(data["t" + tableHeader[4]])}
                                </td>
                                <td>
                                  {getEmptyifZero(data["t" + tableHeader[5]])}
                                </td>
                                <td>
                                  {getEmptyifZero(data["t" + tableHeader[6]])}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  ))}
                <tr className={styles.manualCal}>
                  <td colSpan={2}>
                    <div className="d-flex justify-content-around">
                      <div>
                        <big>Total</big>
                      </div>
                      <strong>
                        <big>{sumValues(totalInputKg)} Kg</big>
                      </strong>
                    </div>
                  </td>
                  <td colSpan={2}>
                    <table
                      className={
                        styles._calTable3 +
                        " table caption-top table-bordered mb-0"
                      }
                      style={{ border: "1px solid rgb(222 226 230)" }}
                    >
                      <tbody>
                        <tr>
                          <td className="text-left pl-2">
                            Cantidad de <br></br>nutrientes en kg/ha
                          </td>
                          <td className={styles._totalValue}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <td>
                                      {getEmptyifZero(
                                        totalRequiredNutrient.addedUpNutrient[
                                          tableHeader[0]
                                        ]
                                      )}
                                    </td>
                                    <td>
                                      {getEmptyifZero(
                                        totalRequiredNutrient.addedUpNutrient[
                                          tableHeader[1]
                                        ]
                                      )}
                                    </td>
                                    <td>
                                      {getEmptyifZero(
                                        totalRequiredNutrient.addedUpNutrient[
                                          tableHeader[2]
                                        ]
                                      )}
                                    </td>
                                    <td>
                                      {getEmptyifZero(
                                        totalRequiredNutrient.addedUpNutrient[
                                          tableHeader[3]
                                        ]
                                      )}
                                    </td>
                                    <td>
                                      {getEmptyifZero(
                                        totalRequiredNutrient.addedUpNutrient[
                                          tableHeader[4]
                                        ]
                                      )}
                                    </td>
                                    <td>
                                      {getEmptyifZero(
                                        totalRequiredNutrient.addedUpNutrient[
                                          tableHeader[5]
                                        ]
                                      )}
                                    </td>
                                    <td>
                                      {getEmptyifZero(
                                        totalRequiredNutrient.addedUpNutrient[
                                          tableHeader[6]
                                        ]
                                      )}
                                    </td>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Corrección manual</td>
                          <td className={styles.innerTable3}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <input
                                      type="number"
                                      value={getEmptyifZero(
                                        totalRequiredNutrient
                                          .manualCorrectionNutrient[
                                          tableHeader[0]
                                        ]
                                      )}
                                      pattern={inputNegativePattern}
                                      onChange={
                                        handleManualCorrectionInputchange
                                      }
                                      name={"m_" + tableHeader[0]}
                                      key={"m_" + tableHeader[0]}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={getEmptyifZero(
                                        totalRequiredNutrient
                                          .manualCorrectionNutrient[
                                          tableHeader[1]
                                        ]
                                      )}
                                      pattern={inputNegativePattern}
                                      onChange={
                                        handleManualCorrectionInputchange
                                      }
                                      name={"m_" + tableHeader[1]}
                                      key={"m_" + tableHeader[1]}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={getEmptyifZero(
                                        totalRequiredNutrient
                                          .manualCorrectionNutrient[
                                          tableHeader[2]
                                        ]
                                      )}
                                      pattern={inputNegativePattern}
                                      onChange={
                                        handleManualCorrectionInputchange
                                      }
                                      name={"m_" + tableHeader[2]}
                                      key={"m_" + tableHeader[2]}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={getEmptyifZero(
                                        totalRequiredNutrient
                                          .manualCorrectionNutrient[
                                          tableHeader[3]
                                        ]
                                      )}
                                      pattern={inputNegativePattern}
                                      onChange={
                                        handleManualCorrectionInputchange
                                      }
                                      name={"m_" + tableHeader[3]}
                                      key={"m_" + tableHeader[3]}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={getEmptyifZero(
                                        totalRequiredNutrient
                                          .manualCorrectionNutrient[
                                          tableHeader[4]
                                        ]
                                      )}
                                      pattern={inputNegativePattern}
                                      onChange={
                                        handleManualCorrectionInputchange
                                      }
                                      name={"m_" + tableHeader[4]}
                                      key={"m_" + tableHeader[4]}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={getEmptyifZero(
                                        totalRequiredNutrient
                                          .manualCorrectionNutrient[
                                          tableHeader[5]
                                        ]
                                      )}
                                      onChange={
                                        handleManualCorrectionInputchange
                                      }
                                      pattern={inputNegativePattern}
                                      name={"m_" + tableHeader[5]}
                                      key={"m_" + tableHeader[5]}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={getEmptyifZero(
                                        totalRequiredNutrient
                                          .manualCorrectionNutrient[
                                          tableHeader[6]
                                        ]
                                      )}
                                      pattern={inputNegativePattern}
                                      onChange={
                                        handleManualCorrectionInputchange
                                      }
                                      name={"m_" + tableHeader[6]}
                                      key={"m_" + tableHeader[6]}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <AddProcduct {...obj} />
        </div>
      ) : (
        <p className="centerOfPage">Loading...</p>
      )}
    </>
  );
};

export default React.memo(DataTable);
