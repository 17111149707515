import React, { FC } from "react";
import styles from "./ConvertedTab.module.scss";
import convertedTabObj from "../convertedTabObj";
import FertigationSpecificationService from "../../../shared/services/FertigationSpecificationService";
import { FilterType } from "../../../shared/models/Enums";

interface ConvertedTabProps {
  token?: any;
  totalRequiredNutrient?: any;
  setManualCorrectionNutrient?: any;
  tableHeader?: any;
  getTabelHeader?: any;
  getPercentage?: any;
  getEmptyifZero?: any;
  roundToTwo?: any;
  checkedFertilizer?: any;
  setCheckedFertilizer?: any;
  checkedNutrients?: any;
  setcheckedNutrients?: any;
  solverResult?: any;
  totalOutputNutrient?: any;
  setTotalOutputNutrient?: any;
  enableCalButton?: any;
}

const ConvertedTab: FC<ConvertedTabProps> = ({
  token,
  totalRequiredNutrient,
  setManualCorrectionNutrient,
  tableHeader,
  getTabelHeader,
  getPercentage,
  getEmptyifZero,
  roundToTwo,
  checkedFertilizer,
  setCheckedFertilizer,
  checkedNutrients,
  setcheckedNutrients,
  solverResult,
  totalOutputNutrient,
  setTotalOutputNutrient,
  enableCalButton,
}) => {
  // convertedTab
  const [yaraRega, setYaraRega] = React.useState([]);
  const [internal, setInternal] = React.useState([]);


  // check uncheck all
  // const [checkStatus, setCheckStatus] = React.useState(false);

  // check uncheck single checkbox 1

  // const [checkedFertilizer, setCheckedFertilizer] = React.useState({});
  let t = null;
  React.useEffect(() => {
    if (token && token != "") {
      FertigationSpecificationService.filterFertigationSpecification(
        [
          {
            key: "ProductType",
            value: "YaraRega, Internal",
            type: FilterType.IN,
          },
        ],
        "name K2O Cl N P2O5 CaO MgO SO3"
      ).then((yaraRega: any) => {
        Object.keys(checkedFertilizer).map((key) => {
          if (checkedFertilizer[key + "_checked"]) {
            let temp = yaraRega?.entities?.find(obj => obj.name == key);
            if (temp) {
              temp.checked = true;
            }
          }
        });
        setYaraRega([...yaraRega.entities]);
      });
      // FertigationSpecificationService.filterFertigationSpecification(
      //   [
      //     {
      //       key: "ProductType",
      //       value: "Internal",
      //       type: FilterType.EQ,
      //     },
      //   ],
      //   "name K2O Cl N P2O5 CaO MgO SO3"
      // ).then((internal: any) => {
      //   console.log([...internal.entities]);
      //   setInternal([...internal.entities]);
      // });
    }
  }, [token]);

  let outputNutrient = {
    N: 0,
    P2O5: 0,
    K2O: 0,
    CaO: 0,
    MgO: 0,
    SO3: 0,
    Cl: 0,
    sum: 0,
  };

  function deviation(result, needed) {
    if (needed == 0) return "";
    return Math.round((result / needed) * 100) + " %";
  }

  function nameHeader(name) {
    switch (name) {
      case "P2O5":
        return (
          <>
            P<sub>2</sub>O<sub>5</sub>
          </>
        );
      case "K2O":
        return (
          <>
            K<sub>2</sub>O
          </>
        );
      case "SO3":
        return (
          <>
            SO<sub>3</sub>
          </>
        );
      default:
        return name;
    }
    // return;
  }

  React.useEffect(() => {
    if (yaraRega.length > 0 && solverResult) {
      yaraRega.map((yaraRegaData) => {
        if (solverResult[yaraRegaData.name]) {
          tableHeader.map((nutrient) => {
            console.log("outputNut", outputNutrient[nutrient]);
            outputNutrient[nutrient] =
              outputNutrient[nutrient] +
              solverResult[yaraRegaData.name] * yaraRegaData[nutrient] * 0.01;
          });
          outputNutrient["sum"] =
            outputNutrient["sum"] + solverResult[yaraRegaData.name];
        }
      });
      // internal.map((yaraRegaData) => {
      //   if (solverResult[yaraRegaData.name]) {
      //     tableHeader.map((nutrient) => {
      //       console.log("outputNut", outputNutrient[nutrient]);
      //       outputNutrient[nutrient] =
      //         outputNutrient[nutrient] +
      //         solverResult[yaraRegaData.name] * yaraRegaData[nutrient] * 0.01;
      //     });
      //     outputNutrient["sum"] =
      //       outputNutrient["sum"] + solverResult[yaraRegaData.name];
      //   }
      // });
      console.log("outputNutrient", outputNutrient);
      setTotalOutputNutrient({
        ...outputNutrient,
      });
    }
  }, [solverResult]);



  // let t = 0;
  const handleSingleCheckboxFertilizer = (event) => {
    let value = JSON.parse(event.target.value);
    let name = event.target.name;
    let capacity = 100;
    let temp = yaraRega?.find(obj => obj.name == event.target.name);

    if (event.target.checked
    ) {
      // console.log(typeof name, " ", name);
      if (name.includes("YARAREGA")) {
        capacity = 1000;
      } else if (name === "YARATERA CALCINIT") {
        capacity = 100;
      } else {
        capacity = 100;
      }

      if (temp) {
        temp.checked = true;
      }
      setCheckedFertilizer({
        ...checkedFertilizer,
        [name]: { ...value, rank: capacity },
        [name + "_checked"]: true,
      });
    } else {
      delete checkedFertilizer[name];
      setCheckedFertilizer({
        ...checkedFertilizer,
        [name + "_checked"]: false,
      });
      if (temp) {
        temp.checked = false;
      }


    }
    console.log('Praneeth yara rega', yaraRega);

    if (yaraRega.filter(obj => obj.name.includes("YARAREGA") && obj.checked)?.length ?? 0 > 0) {
      if (yaraRega.filter(obj => obj.name.includes("YARATERA KRIS") && obj.checked)?.length > 2) {
        enableCalButton(false);
      } else {
        enableCalButton(true);
      }
    } else {
      enableCalButton(false);
    }

  };

  const handleSingleCheckboxNutrients = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if (event.target.checked) {
      console.log("in the if");
      // console.log(typeof );
      setcheckedNutrients({
        ...checkedNutrients,
        [name]: value,
        [name + "_checked"]: true,
      });
    } else {
      console.log("in the else");
      delete checkedNutrients[name];
      setcheckedNutrients({
        ...checkedNutrients,
        [name + "_checked"]: false,
      });
      // delete checkedNutrients[name + "_checked"];
      // checkedNutrients[name + "_checked"] = false;
    }
  };

  // const handleChBoxStatus = (pos) => {
  //   setCheckStatus(!checkStatus);
  //   if (checkStatus) {
  //     const updateState = singleCheck1.map((ele, index) => (ele = false));
  //     setSingleCheck1(updateState);
  //   } else {
  //     const updateState = singleCheck1.map((ele, index) => (ele = true));
  //     setSingleCheck1(updateState);
  //   }
  // };

  return (
    <div className={styles.ConvertedTab}>
      {/* {JSON.stringify(totalOutputNutrient)}
      {JSON.stringify(totalRequiredNutrient)} */}
      {/* second section starts */}
      <div className={styles.DataTable} data-testid="DataTable">
        <div className="table-responsive mt-md-3 mt-2">
          <table
            width="100%"
            className={styles._calTable + " table caption-top table-bordered"}
          >
            <thead>
              <tr>
                <td scope="col text-center" align="center" valign="middle">
                  {/* <input
                    type="checkbox"
                    // checked={allCheck2}
                    name="All Value"
                    value={JSON.stringify({ ...yaraRega, ...internal })}
                    onChange={handleSingleCheckboxFertilizer}
                    className={styles._chBox}
                  /> */}
                  {/* <strong className="d-block">Select</strong> */}
                </td>
                <td
                  scope="col text-left"
                  style={{ textAlign: "left", width: "400px" }}
                >
                  <strong className="d-block">Producto</strong>
                  <div className="text-left">
                    <small>Nombre</small>
                  </div>
                </td>
                <td
                  scope="col"
                  style={{ textAlign: "right", paddingRight: "20px" }}
                >
                  <strong className="d-block">Cantidad</strong>
                  <table style={{ float: "right" }}>
                    <tbody>
                      <tr>
                        {
                          <>
                            <td style={{ textAlign: "right" }}>
                              <small>Kg/ha</small>
                            </td>
                          </>
                        }
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td scope="col">
                  <strong>Nutrientes</strong>
                  <table className={styles.headerTable2}>
                    <tbody>
                      <tr>
                        {
                          <>
                            <td>
                              <small>N</small>
                            </td>
                            <td>
                              <small>
                                P<sub>2</sub>
                              </small>
                              <small>
                                O<sub>5</sub>
                              </small>
                            </td>
                            <td>
                              <small>
                                K<sub>2</sub>
                              </small>
                              <small>O</small>
                            </td>
                            <td>
                              <small>MgO</small>
                            </td>
                            <td>
                              <small>
                                SO<sub>3</sub>
                              </small>
                            </td>
                            <td>
                              <small>CaO</small>
                            </td>
                            <td>Cl</td>
                          </>
                        }
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </thead>
            <tbody>
              {yaraRega.length > 0 &&
                yaraRega.map((data) => (
                  <>
                    <tr
                      style={
                        data.name.includes("YARAREGA")
                          ? {}
                          : { backgroundColor: "#F9F9F9" }
                      }
                    >
                      <td align="center" valign="middle">
                        <input
                          type="checkbox"
                          checked={checkedFertilizer[data.name + "_checked"]}
                          name={data.name}
                          value={JSON.stringify(data)}
                          onChange={handleSingleCheckboxFertilizer}
                          className={styles._chBox}
                        />
                      </td>
                      <td className={styles.f14}>{data.name}</td>
                      <td className="pr-4">
                        <table style={{ maxWidth: "100%", float: "right" }}>
                          <tbody>
                            <tr>
                              <td className="text-right">
                                {solverResult[data.name]
                                  ? roundToTwo(solverResult[data.name])
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              {
                                <>
                                  <td>
                                    {solverResult[data.name]
                                      ? roundToTwo(
                                        solverResult[data.name] *
                                        getPercentage(data.N, 1)
                                      )
                                      : ""}
                                  </td>
                                  <td>
                                    {solverResult[data.name]
                                      ? roundToTwo(
                                        solverResult[data.name] *
                                        getPercentage(data.P2O5, 1)
                                      )
                                      : ""}
                                  </td>
                                  <td>
                                    {solverResult[data.name]
                                      ? roundToTwo(
                                        solverResult[data.name] *
                                        getPercentage(data.K2O, 1)
                                      )
                                      : ""}
                                  </td>
                                  <td>
                                    {solverResult[data.name]
                                      ? roundToTwo(
                                        solverResult[data.name] *
                                        getPercentage(data.MgO, 1)
                                      )
                                      : ""}
                                  </td>
                                  <td>
                                    {solverResult[data.name]
                                      ? roundToTwo(
                                        solverResult[data.name] *
                                        getPercentage(data.SO3, 1)
                                      )
                                      : ""}
                                  </td>
                                  <td>
                                    {solverResult[data.name]
                                      ? roundToTwo(
                                        solverResult[data.name] *
                                        getPercentage(data.CaO, 1)
                                      )
                                      : ""}
                                  </td>
                                  <td>
                                    {solverResult[data.name]
                                      ? roundToTwo(
                                        solverResult[data.name] *
                                        getPercentage(data.Cl, 1)
                                      )
                                      : ""}
                                  </td>
                                </>
                              }
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </>
                ))}
              {/* {internal.length > 0 &&
                internal.map((data) => (
                  <>
                    <tr>
                      <td
                        align="center"
                        valign="middle"
                        style={{ backgroundColor: "#F9F9F9" }}
                      >
                        <input
                          type="checkbox"
                          name={data.name}
                          value={JSON.stringify(data)}
                          checked={checkedFertilizer[data.name + "_checked"]}
                          onChange={handleSingleCheckboxFertilizer}
                          className={styles._chBox}
                        />
                      </td>
                      <td
                        className={styles.f14}
                        style={{ backgroundColor: "#F9F9F9" }}
                      >
                        {data.name}
                      </td>
                      <td
                        className="pr-4"
                        style={{ backgroundColor: "#F9F9F9" }}
                      >
                        <table style={{ maxWidth: "100%", float: "right" }}>
                          <tbody>
                            <tr>
                              <td
                                className="text-right"
                                style={{ backgroundColor: "#F9F9F9" }}
                              >
                                {solverResult[data.name]
                                  ? roundToTwo(solverResult[data.name])
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style={{ backgroundColor: "#F9F9F9" }}>
                        <table>
                          <tbody>
                            <tr>
                              {
                                <>
                                  <td>
                                    {solverResult[data.name]
                                      ? roundToTwo(
                                          solverResult[data.name] *
                                            getPercentage(data.N, 1)
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    {solverResult[data.name]
                                      ? roundToTwo(
                                          solverResult[data.name] *
                                            getPercentage(data.P2O5, 1)
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    {solverResult[data.name]
                                      ? roundToTwo(
                                          solverResult[data.name] *
                                            getPercentage(data.K2O, 1)
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    {solverResult[data.name]
                                      ? roundToTwo(
                                          solverResult[data.name] *
                                            getPercentage(data.MgO, 1)
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    {solverResult[data.name]
                                      ? roundToTwo(
                                          solverResult[data.name] *
                                            getPercentage(data.SO3, 1)
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    {solverResult[data.name]
                                      ? roundToTwo(
                                          solverResult[data.name] *
                                            getPercentage(data.CaO, 1)
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    {solverResult[data.name]
                                      ? roundToTwo(
                                          solverResult[data.name] *
                                            getPercentage(data.Cl, 1)
                                        )
                                      : ""}
                                  </td>
                                </>
                              }
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </>
                ))} */}
              <tr>
                <td colSpan={2}>
                  <div className="d-flex justify-content-between text-center">
                    <div
                      className={
                        styles._totKg +
                        " p-0 d-flex justify-content-between text-right"
                      }
                    >
                      <strong>
                        <big>Total Kg</big>
                      </strong>
                    </div>
                  </div>
                </td>

                <td className="p-0">
                  <div className={styles._toKg + " p-md-2 pr-md-4 text-right"}>
                    {totalOutputNutrient["sum"] != 0 && totalOutputNutrient
                      ? roundToTwo(totalOutputNutrient["sum"])
                      : ""}
                  </div>
                </td>
                <td className="p-0">
                  {/* <div className={styles._totKgR}> */}
                  <div className="d-flex justify-content-between p-2">
                    {
                      <table>
                        <tbody>
                          <tr>
                            {tableHeader.length > 0 &&
                              tableHeader.map((data, index) => (
                                <>
                                  <td>
                                    {totalOutputNutrient[data] != 0
                                      ? roundToTwo(totalOutputNutrient[data])
                                      : ""}
                                  </td>
                                </>
                              ))}
                          </tr>
                        </tbody>
                      </table>
                    }
                  </div>
                  {/* </div> */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className={styles.DataTable} data-testid="DataTable">
        <div className="table-responsive mt-md-3 mt-2">
          <table
            width="100%"
            className={
              styles._calTable +
              " " +
              styles.Dt1 +
              " table caption-top table-bordered"
            }
          >
            <thead>
              <tr>
                <td
                  scope="col text-center"
                  align="center"
                  valign="middle"
                  style={{ width: "50px" }}
                >
                  {/* <input
                    type="checkbox"
                    checked={checkStatus}
                    onChange={handleChBoxStatus}
                    className={styles._chBox}
                  /> */}
                  {/* <strong className="d-block">Select</strong> */}
                </td>
                <td scope="col" className="text-center" valign="top">
                  <strong className="d-block">Nutrientes</strong>
                </td>
                <td scope="col" valign="top" className="text-center">
                  <strong className="d-block">Cantidad necesaria</strong>
                  <div>
                    <small>Kg/ha</small>
                  </div>
                </td>
                <td scope="col" valign="top" className="text-center">
                  <strong className="d-block">Resultado</strong>
                  <div>
                    <small>Kg/ha</small>
                  </div>
                </td>
                {/* <td className="text-center" valign="top">
                  <strong>Result</strong>
                </td> */}
                <td className="text-center" valign="top">
                  <strong>Desviación</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {tableHeader.length > 0 &&
                tableHeader.map((data, index) => (
                  <>
                    <tr>
                      <td align="center" valign="middle">
                        <input
                          type="checkbox"
                          value={
                            parseFloat(
                              totalRequiredNutrient.addedUpNutrient[data]
                            ) +
                            parseFloat(
                              totalRequiredNutrient.manualCorrectionNutrient[
                                data
                              ]
                                ? totalRequiredNutrient
                                  .manualCorrectionNutrient[data]
                                : 0
                            )
                          }
                          name={data}
                          checked={checkedNutrients[data + "_checked"]}
                          onClick={handleSingleCheckboxNutrients}
                          className={styles._chBox}
                        />
                      </td>
                      <td className={styles.f14 + " text-center"}>
                        {nameHeader(data)}
                      </td>
                      <td>
                        <table style={{ maxWidth: "100%", float: "right" }}>
                          <tbody>
                            <tr>
                              <td className="text-center">
                                {totalRequiredNutrient.addedUpNutrient[data] !=
                                  0
                                  ? roundToTwo(
                                    parseFloat(
                                      totalRequiredNutrient.addedUpNutrient[
                                      data
                                      ]
                                    ) +
                                    parseFloat(
                                      totalRequiredNutrient
                                        .manualCorrectionNutrient[data]
                                    )
                                  )
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                {totalOutputNutrient[data] != 0 ||
                                  isNaN(totalOutputNutrient[data])
                                  ? roundToTwo(totalOutputNutrient[data])
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                {totalOutputNutrient[data] != 0 ||
                                  isNaN(totalOutputNutrient[data])
                                  ? deviation(
                                    parseFloat(totalOutputNutrient[data]),
                                    parseFloat(
                                      totalRequiredNutrient.addedUpNutrient[
                                      data
                                      ]
                                    ) +
                                    parseFloat(
                                      totalRequiredNutrient
                                        .manualCorrectionNutrient[data]
                                    )
                                  )
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ConvertedTab;
