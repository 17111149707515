import axios from "axios";
let http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

http.interceptors.request.use((request: any) => {
  if (request.url ?? "".indexOf("login") === -1) {
    let tokens: any = sessionStorage.getItem("acess_token");
    if (tokens) {
      tokens = JSON.parse(tokens);
      request.headers.common[
        "authorization"
      ] = `Bearer ${tokens?.access_token_user?.replace(/['"]+/g, "")}`;
      request.headers.common[
        "access-token-engine"
      ] = `${tokens?.access_token_engine?.replace(/['"]+/g, "")}`;
      request.headers.common[
        "access-token-gateway"
      ] = `${tokens?.access_token_gateway?.replace(/['"]+/g, "")}`;
      request.headers.common["language"] = "es";
    }
  }
  return request;
});

http.interceptors.response.use((response) => {
  if (response.request.responseURL.indexOf("graphql") !== -1) {
    response.data = response.data.data[Object.keys(response.data.data)[0]];
  }

  return response;
});

export default http;
