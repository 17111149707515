import React, { FC, useEffect } from 'react';
import SidebarButtons from '../SidebarButtons/SidebarButtons';
import styles from './Sidebar.module.scss';
import { useParams, useMatch } from 'react-router-dom';


interface SidebarProps {
  display?:boolean
}

const Sidebar: FC<SidebarProps> = ({display}) => {
  return <div className={styles.Sidebar+" p-2"} data-testid="Sidebar">
      <div className='d-flex align-items-center flex-column'>
        <span className="yaraLogo editF d-block mt-2 mb-2 "></span>
        <SidebarButtons display={display}/>
      </div>
  </div>
}

export default Sidebar;
