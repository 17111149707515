import React, { FC } from "react";
import styles from "./Footer.module.scss";

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
  return <div className={styles.Footer + " m-0 p-0"} data-testid="Footer" style={{borderTop:'1px solid #f0f0f0'}}>
    <div className="float-right m-1 mb-1 mr-3 position-relative">
      <a
        target="_blank"
        href="https://www.yara.com/privacy-and-legal/digital-farming-privacy/digital-farming-privacy-spanish/"
        style={{fontSize:"13px",color:"#133F7A"}}
      >
        Privacidad
      </a>
    </div>
    <div className="float-right m-1 mb-1 mr-3 position-relative">
      <a
        target="_blank"
        href="https://www.yara.com/privacy-and-legal/digital-farming-terms/digital-farming-terms-spanish/" 
        style={{fontSize:"13px",color:"#133F7A"}}
      >
        Legal
      </a>
    </div>
  </div>
}

export default Footer;
