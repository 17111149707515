// import { Popover } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { FilterType } from "../../shared/models/Enums";
// import Popover from "@mui/material/Popover";
import FertigationSpecificationService from "../../shared/services/FertigationSpecificationService";
import AddProcduct from "../AddProcduct/AddProcduct";
import styles from "./FertigationSpecification.module.scss";
import DataTable from "./DataTable/DataTable";
import ConvertedTab from "./ConvertedTab/ConvertedTab";
import { Button } from "@mui/material";
import analyticsService from "../../shared/services/AnalyticsService";
import { useAuth0 } from "@auth0/auth0-react";
import { Console } from "console";

interface FertigationSpecificationProps { }

const FertigationSpecification: FC<FertigationSpecificationProps> = () => {
  let tableHeader: string[] = ["N", "P2O5", "K2O", "MgO", "SO3", "CaO", "Cl"];
  const [token, setToken] = React.useState("");
  let url = process.env.REACT_APP_API_URL;
  // state for getting data from databasde
  // state for user input value in textbox
  // Per Nutrient total value
  const [totalRequiredNutrient, setManualCorrectionNutrient] = React.useState({
    addedUpNutrient: { N: 0, P2O5: 0, K2O: 0, CaO: 0, MgO: 0, SO3: 0, Cl: 0 },
    manualCorrectionNutrient: {
      N: 0,
      P2O5: 0,
      K2O: 0,
      CaO: 0,
      MgO: 0,
      SO3: 0,
      Cl: 0,
    },
  });
  const [totalOutputNutrient, setTotalOutputNutrient] = React.useState({
    N: 0,
    P2O5: 0,
    K2O: 0,
    CaO: 0,
    MgO: 0,
    SO3: 0,
    Cl: 0,
    sum: 0,
  });
  // setTabs
  const initialNutrientCheckData = {
    [tableHeader[0] + "_checked"]: true,
    [tableHeader[2] + "_checked"]: true,
    [tableHeader[5] + "_checked"]: true

  }
  const [activeTab, setActiveTab] = React.useState(true);
  // check fertilizer in converted tab
  const [checkedFertilizer, setCheckedFertilizer] = React.useState({});
  // check Nutrients in converted tab
  const [checkedNutrients, setcheckedNutrients]: any = React.useState(initialNutrientCheckData);
  const [fertigationSpec, setFertigationSpec] = React.useState([]);

  const [solverResult, setSolverResult]: any = React.useState({});

  const [totalInputKg, setTotalInputKg] = React.useState({
    a: 0,
  });
  const [enableCalculate, setEnableCalculate] = React.useState(false);

  const enableCalButton = (dataFromChild) => {
    console.log(dataFromChild);
    setEnableCalculate(dataFromChild);
  };

  const { user } = useAuth0();

  React.useEffect(() => {
    if (token && token != "") {
      FertigationSpecificationService.filterFertigationSpecification(
        [
          {
            key: "modifiedBy",
            value: `adminExternalFertilizer, ${user?.name}`,
            type: FilterType.IN,
          },
        ],
        "name K2O Cl N P2O5 CaO MgO SO3"
      ).then((fertigationSpec: any) => {
        console.log(fertigationSpec.entities);
        setFertigationSpec([...fertigationSpec.entities]);
      });
    }
  }, [token]);
  
  let userD = null;
  React.useEffect(() => {
    userD = JSON.parse(window.sessionStorage.getItem("userData"));
    analyticsService.page(
      "Conversion Calculator page",
      null,
      null,
      null,
      null,
      null,
      userD["https://fertigation-as-a-service/country-name"],
      null
    );
  }, []);

  function getTabelHeader(percentStr = "") {
    return (
      <tbody>
        <tr>
          {
            <>
              <td>
                <small>{percentStr + "N"}</small>
              </td>
              <td>
                <small>
                  {percentStr + "P"}
                  <sub>2</sub>O<sub>5</sub>
                </small>
              </td>
              <td>
                <small>
                  {percentStr + "K"}
                  <sub>2</sub>O
                </small>
              </td>
              <td>
                <small>
                  {percentStr + "M"}
                  gO
                </small>
              </td>
              <td>
                <small>
                  {percentStr + "S"}O<sub>3</sub>
                </small>
              </td>
              <td>
                <small>{percentStr + "Ca"}O</small>
              </td>
              <td>
                <small>{percentStr + "C"}l</small>
              </td>
            </>
          }
        </tr>
      </tbody>
    );
  }

  function getEmptyifZero(Num: number): any {
    return Num === 0 ? "" : Num;
  }

  React.useEffect(() => {
    let timer = setTimeout(() => {
      setToken(sessionStorage.getItem("acess_token") ?? "");
      if (token && token != "") {
        clearTimeout(timer);
      }
    }, 3000);
  }, []);

  function roundToTwo(num: number) {
    return +(Math.round(Number(num + "e+2")) + "e-2");
  }
  const getPercentage = (qty: number, key: any) =>
    roundToTwo(qty * (key / 100));

  const getPercentageOnly = (qty: number, key: any) => qty * (key / 100);

  const handleActivChange = () => {
    setActiveTab(!activeTab);
  };

  function handleCalculate(event: any) {
    // event.preventDefault();
    analyticsService.track(
      "Conversion Calculation Requested",
      null,
      null,
      null,
      null,
      null,
      // userD["https://fertigation-as-a-service/country-name"],
      null,
      null
    );
    let constraints = {};
    let variables = {};
    let cao_Checked = false;
    // let optimize = {};
    Object.keys(checkedNutrients).map((key) => {
      if (
        parseFloat(checkedNutrients[key]) !== 0 &&
        checkedNutrients[key]
      ) {
        // if (key == "K2O") {
        //   constraints[key] = {
        //     // equal: parseFloat(checkedNutrients[key]),
        //     max: parseFloat(checkedNutrients[key]),
        //     min: parseFloat(checkedNutrients[key]),
        //   };
        // } else {
        const temp_key = key.replace('_checked', '');
        if (temp_key === tableHeader[5]) {
          cao_Checked = true;
        }
        constraints[temp_key] = {
          // equal: parseFloat(checkedNutrients[key]),
          max: parseFloat(
            totalRequiredNutrient.addedUpNutrient[temp_key]
          ) +
            parseFloat(
              totalRequiredNutrient.manualCorrectionNutrient[
                temp_key
              ]
                ? totalRequiredNutrient
                  .manualCorrectionNutrient[temp_key]
                : 0
            ),
          // min: parseFloat(checkedNutrients[key]),
        };
        // }
        // optimize[key] = "max";
      }
      // delete constraints[key + "_checked"];
    });
    Object.keys(checkedFertilizer).map((key) => {
      if (checkedFertilizer[key + "_checked"]) {
        Object.keys(checkedFertilizer[key]).map((keyObject) => {
          if (checkedFertilizer[key][keyObject] !== 0) {
            variables[key] = {
              ...variables[key],
              // [keyObject]: getPercentageOnly(
              //   checkedFertilizer[key][keyObject],
              //   1
              // ),
              [keyObject]: parseFloat(
                (checkedFertilizer[key][keyObject] * 0.01).toFixed(4)
              ),
            };
          }
        });
       

        if ((Object.keys(variables[key]).filter(obj => obj == tableHeader[5]).length < 1) && cao_Checked) {
          variables[key] = {
            ...variables[key], [tableHeader[5]]: 0
            // capacity: 1,
          };
        }
        else {
          variables[key] = {
            ...variables[key]
            // capacity: 1,
          };
        }
        // integer = {
        //   ...integer,
        //   [key]: 1,
        // };
        delete variables[key].name;
      }
      // delete variables[key + "_checked"];
    });
    console.log(constraints);
    console.log(variables);
    // console.log(optimize);
    let model = {
      optimize: "rank",
      opType: "max",
      constraints: {
        ...constraints,
      },
      variables: {
        ...variables,
      },
      // ints: { ...integer },
    };
    console.log({ ...model });
    console.log(solver.Solve(model));
    setSolverResult(solver.Solve(model));
    // console.log(model);
  }

  const props = {
    token,
    totalRequiredNutrient,
    setManualCorrectionNutrient,
    tableHeader,
    getTabelHeader,
    getPercentage,
    getEmptyifZero,
    roundToTwo,
    checkedFertilizer,
    setCheckedFertilizer,
    checkedNutrients,
    setcheckedNutrients,
    fertigationSpec,
    setFertigationSpec,
    solverResult,
    setSolverResult,
    totalOutputNutrient,
    setTotalOutputNutrient,
    totalInputKg,
    setTotalInputKg,
    enableCalButton,
    initialNutrientCheckData
  };

  return (
    <>
      {/* {JSON.stringify(solverResult)} */}
      <div className={styles.DataTable} data-testid="DataTable">
        <div
          className={
            styles.FertigationSpecification + " mt-md-3 mt-1 p-1 p-md-4"
          }
          data-testid="FertigationSpecification"
        >
          <div className="container-fluid">
            <div className="d-md-flex justify-content-between">
              <h3 className="col-md-6 col-12 m-md-0 mb-2 mt-2 p-0 text-left">
                <strong>Calculadora nutricional</strong>
                <a
                  href={process.env.REACT_APP_API_URL + "helpfile"}
                  target="_blank"
                  className={styles.help + " d-inline-block ml-2"}
                ></a>
              </h3>
              {activeTab ? (
                ""
              ) : (
                <h3 className="col-md-1 col-5 m-md-0 mt-3 mb-2 p-0 ml-auto">
                  <Button
                    // data-testid=""
                    fullWidth
                    onClick={handleCalculate}
                    className={"specificBtton noto-sans-bold main_btn"}
                    variant="contained"
                    type="submit"
                    disabled={!enableCalculate}
                  >
                    Calcular
                  </Button>
                </h3>
              )}
              {/* <h3 className="col-md-1 col-5 m-md-0 mt-3 mb-2 p-0 ml-auto">
                <Button
                  // data-testid=""
                  fullWidth
                  onClick={handleCalculate}
                  className={"specificBtton noto-sans-bold main_btn"}
                  variant="contained"
                  type="submit"
                >
                  Calculate
                </Button>
              </h3> */}
            </div>
            <div
              className={styles.tabs + " d-flex justify-content-start mt-md-4"}
            >
              <span
                className={
                  activeTab
                    ? styles.active + " d-inline-block p-0 m-0 text-left mr-3"
                    : " d-inline-block p-0 m-0 text-left mr-3"
                }
                onClick={handleActivChange}
              >
                Original
              </span>
              <span
                className={
                  !activeTab
                    ? styles.active + " d-inline-block p-0 m-0 text-left mr-3"
                    : " d-inline-block p-0 m-0 text-left mr-3"
                }
                onClick={handleActivChange}
              >
                Resultado
              </span>
            </div>
            {activeTab ? <DataTable {...props} /> : <ConvertedTab {...props} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default FertigationSpecification;
