import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import styles from "./SidebarButtons.module.scss";

interface SidebarButtonsProps {
  display?: boolean;
}

const SidebarButtons: FC<SidebarButtonsProps> = ({ display }) => {
  // const [isActive, setIsActive] = React.useState(2);
  const handleClick = () => {
    // setIsActive(!isActive);
  };

  if (true) {
    return (
      <div className={styles.SidebarButtons} data-testid="SidebarButtons">
        <NavLink
          to="/viewfertigaionplan"
          onClick={handleClick}
          className={
            styles.sideBbttn +
            " " +
            styles._ferg +
            " pb-2 pt-2 d-inline-block btnActive "
          }
        >
          <span className={styles.barLogo + " d-block ml-auto mr-auto"}></span>
          <p className="fPvSb mb-0">Plan de fertirrigación</p>
        </NavLink>

        <NavLink
          to="/fertigationspecification"
          onClick={handleClick}
          className={
            styles.sideBbttn +
            " " +
            styles.calculate +
            " pb-2 pt-2 d-inline-block btnActive "
          }
        >
          {/* <span
              className={styles.calcLogo + " d-block ml-auto mr-auto"}
            ></span> */}
          <span className={styles._YaraRega + " d-block"}></span>
          <p className="fPvSb mb-0">Calculadora nutricional</p>
        </NavLink>

      </div>
    );
  }
  return <span></span>;
};

export default SidebarButtons;
