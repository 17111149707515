import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import {
  ProtectedRoute,
  Auth0ProviderWithRedirectCallback,
} from "./shared/Auth0ProtectRoute";

// replace console.* for disable log on production
if (process.env.REACT_APP_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Auth0ProviderWithRedirectCallback
        domain={process.env.REACT_APP_DOMAIN as string} //"stage-farmer-spain.eu.auth0.com" "dev-farmer-spain.eu.auth0.com"
        ClientName={process.env.REACT_APP_CLIENT_NAME} //"fertigation-as-a-service" "Fertigation as a Service"
        clientId={process.env.REACT_APP_CLIENT_ID as string} //"LG9ZFTaCLZmeAp8Z8ZggHrUoiWUMIzlu" "laznQTgs8XlLDHZqirKsWNphixhYcVbq"
        ClientSecret={process.env.REACT_APP_CLIENT_SECRET} //"_LTIAIiQ5S0iXdGTLu14LP5rcA-QC4NNBBF2faJPb40A54dqv--dtjn5rKpF8_IS" "newJew0Xwb8W8IZaeysby09eDRazAcoMx8Qv25cTe2kNu63Z9S3S_cKj1rohFDqO"
        Audience={process.env.REACT_APP_AUDIENCE} //"https://stage-farmer-spain.eu.auth0.com/api/v2/"
        // domain="yara-connect-ghana-dev.eu.auth0.com"
        // clientId="w0Yee3fYV0O37GlMxU1VllRq5gFMRkEi"
        redirectUri={window.location.origin}
      >
        <App></App>
      </Auth0ProviderWithRedirectCallback>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
