import http from "./HttpCommon";
import GenericFilter from "../models/GenericFilter";
import FertigationSpecification from "../models/FertigationSpecification";

const filterFertigationSpecification = (
  filters: GenericFilter[],
  includeOutquery: string
) => {
  let filtersStr: string = "";
  if (filters?.length > 0) {
    filtersStr = filters
      .map(
        (obj: GenericFilter) =>
          `{
      key: "${obj.key}"
      value: "${obj.value}"
      type: ${obj.type}
      }`
      )
      .join("");
  }

  const getFieldByNameQuery = `
  query{
    getFertilizerSpecifications(
    filter: [${filtersStr}] 
    )
    {
      entities{ ${includeOutquery}}
    }
  
  }`;

  return http
    .request<FertigationSpecification>({
      url: "graphql",
      method: "POST",
      data: { query: getFieldByNameQuery },
    })
    .then((response) => response.data)
    .catch((error) => {
      // console.log(error);
      return null;
    });
};

const create = (data: FertigationSpecification, includeOutquery: string) => {
  const getUserbyNameQuery = `
      mutation{
        createFertilizerSpecifications(input: {
            name: "${data.name}",
            N: ${data.N},
            CaO: ${data.CaO},
            P2O5: ${data.P2O5},
            K2O: ${data.K2O},
            MgO: ${data.MgO},
            SO3: ${data.SO3},
            Cl: ${data.Cl},
            ProductType: "${data.ProductType}",
            modifiedBy: "${data.modifiedBy}"
          }){
         ${includeOutquery}
          }
          }`;

  return http
    .request<FertigationSpecification>({
      url: "graphql",
      method: "POST",
      data: { query: getUserbyNameQuery },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      return null;
    });
};

const FertigationSpecificationService = {
  filterFertigationSpecification,
  create,
};
export default FertigationSpecificationService;
