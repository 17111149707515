import React, { FC } from "react";
import styles from "./AddProcduct.module.scss";
import Button from "@mui/material/Button";
import MuiPopper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import FertigationSpecificationService from "../../shared/services/FertigationSpecificationService";
import FertigationSpecification from "../../shared/models/FertigationSpecification";
import analyticsService from "../../shared/services/AnalyticsService";
import { useAuth0 } from "@auth0/auth0-react";

interface AddProcductProps {
  handleBlur?: any;
  setFertigationSpec?: any;
  fertigationSpec?: any;
}

const AddProcduct: FC<AddProcductProps> = ({
  handleBlur,
  setFertigationSpec,
  fertigationSpec,
}) => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const defaultValues: any = {
    name: "",
    N: 0,
    CaO: 0,
    P2O5: 0,
    K2O: 0,
    MgO: 0,
    SO3: 0,
    Cl: 0,
    ProductType: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues });
  const [data, setData] = React.useState(null);
  const { user } = useAuth0();

  let userD = null;
  const handleClickButton = () => {
    userD = JSON.parse(window.sessionStorage.getItem("userData"));
    analyticsService.track(
      "Product Added",
      sessionStorage.getItem("channelName"),
      null,
      null,
      null,
      null,
      userD["https://fertigation-as-a-service/country-name"],
      null
    );
    setOpen((prevOpen) => !prevOpen);
    reset(data);
    setData({
      ...data,
      ...defaultValues,
    });
    handleBlur(open);
  };

  React.useEffect(() => {
    console.log("Add Product..");
  }, []);
  return (
    <div className={styles.AddProcduct} data-testid="AddProcduct">
      <Button
        ref={anchorRef}
        variant="contained"
        className={styles._addProd + " d-flex"}
        onClick={handleClickButton}
      >
        <span>Añadir producto</span>
      </Button>
      <div className={styles.popup}>
        <MuiPopper placement="top-end" open={open} anchorEl={anchorRef.current}>
          <div>
            <Paper
              sx={{
                maxWidth: 600,
                overflowY: "auto",
              }}
            >
              <DialogTitle
                className={
                  styles?.h2 +
                  " noto-sans-bold text-center mt-md-4 pt-md-4 pt-3 " +
                  styles?.h4
                }
              >
                <strong>{"Añadir producto"}</strong>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <div className="col-11 m-auto">
                    <form
                      className={styles.addProForm + " row g-2 pt-4"}
                      onSubmit={handleSubmit(async (data) => {
                        setData(JSON.stringify(data));
                        const fertigationSpecification: FertigationSpecification =
                          {
                            name: data.name,
                            N: data.N,
                            CaO: data.CaO,
                            P2O5: data.P2O5,
                            K2O: data.K2O,
                            MgO: data.MgO,
                            SO3: data.SO3,
                            Cl: data.Cl,
                            ProductType: "External",
                            modifiedBy: user?.name,
                          };
                        await FertigationSpecificationService.create(
                          fertigationSpecification,
                          "id"
                        ).then((databaseId) => {
                          console.log("Add Product id:- ", databaseId);
                          setFertigationSpec([
                            ...fertigationSpec,
                            {
                              name: data.name,
                              N: parseFloat(data.N),
                              CaO: parseFloat(data.CaO),
                              P2O5: parseFloat(data.P2O5),
                              K2O: parseFloat(data.K2O),
                              MgO: parseFloat(data.MgO),
                              SO3: parseFloat(data.SO3),
                              Cl: parseFloat(data.Cl),
                            },
                          ]);
                          // console.log("Add Product id:- ", fertigationSpec);
                        });
                        setOpen((prevOpen) => !prevOpen);
                        handleBlur(open);
                        reset(data);
                        setData({
                          ...data,
                          ...defaultValues,
                        });
                      })}
                    >
                      <div className="col-md-12">
                        <label
                          htmlFor="name"
                          className={"form-label " + styles?.input}
                        >
                          <small>Nombre</small>
                        </label>
                        <input
                          id="name"
                          className={styles?.text + " form-control"}
                          {...register("name", {
                            required: true,
                            maxLength: 200,
                          })}
                        />
                        {errors.name && errors.name.type === "required" && (
                          <div className={styles.error}>This is required</div>
                        )}
                        {errors.name && errors.name.type === "maxLength" && (
                          <div className={styles.error}>
                            Max length exceeded
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="N" className="form-label mt-md-2">
                          <small> %N</small>
                        </label>
                        <input
                          id="N"
                          type="number"
                          step="0.01"
                          className={"form-control " + styles.input}
                          {...register("N", { min: 0, max: 100 })}
                        />
                        {errors.N && errors.N.type === "min" && (
                          <div className={styles.error}>
                            Your N must be at least 0
                          </div>
                        )}
                        {errors.N && errors.N.type === "max" && (
                          <div className={styles.error}>
                            Your N must be not more than 100
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="P2O5" className="form-label mt-md-2">
                          <small>
                            %P<sub>2</sub>O<sub>5</sub>
                          </small>
                        </label>
                        <input
                          id="P2O5"
                          type="number"
                          step="0.01"
                          className={"form-control " + styles.input}
                          {...register("P2O5", { min: 0, max: 100 })}
                        />
                        {errors.P2O5 && errors.P2O5.type === "min" && (
                          <div className={styles.error}>
                            <small>
                              Your P<sub>2</sub>O<sub>5</sub> must be at least 0
                            </small>
                          </div>
                        )}
                        {errors.P2O5 && errors.P2O5.type === "max" && (
                          <div className={styles.error}>
                            Your P<sub>2</sub>O<sub>5</sub> must be not more
                            than 100
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="K2O" className="form-label mt-md-2">
                          <small>
                            %K<sub>2</sub>O
                          </small>
                        </label>
                        <input
                          id="K2O"
                          type="number"
                          step="0.01"
                          className={"form-control " + styles.input}
                          {...register("K2O", { min: 0, max: 100 })}
                        />
                        {errors.K2O && errors.K2O.type === "min" && (
                          <div className={styles.error}>
                            Your K<sub>2</sub>O must be at least 0
                          </div>
                        )}
                        {errors.K2O && errors.K2O.type === "max" && (
                          <div className={styles.error}>
                            Your K<sub>2</sub>O must be not more than 100
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="CaO" className="form-label mt-md-2">
                          <small>%CaO</small>
                        </label>
                        <input
                          id="CaO"
                          type="number"
                          step="0.01"
                          className={"form-control " + styles.input}
                          {...register("CaO", { min: 0, max: 100 })}
                        />
                        {errors.CaO && errors.CaO.type === "min" && (
                          <div className={styles.error}>
                            Your CaO must be at least 0
                          </div>
                        )}
                        {errors.CaO && errors.CaO.type === "max" && (
                          <div className={styles.error}>
                            Your CaO must be not more than 100
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="MgO" className="form-label mt-md-2">
                          <small>%MgO</small>
                        </label>
                        <input
                          id="MgO"
                          type="number"
                          step="0.01"
                          className={"form-control " + styles.input}
                          {...register("MgO", { min: 0, max: 100 })}
                        />
                        {errors.MgO && errors.MgO.type === "min" && (
                          <div className={styles.error}>
                            Your MgO must be at least 0
                          </div>
                        )}
                        {errors.MgO && errors.MgO.type === "max" && (
                          <div className={styles.error}>
                            Your MgO must be not more than 100
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="SO3" className="form-label mt-md-2">
                          <small>
                            %SO<sub>3</sub>
                          </small>
                        </label>
                        <input
                          id="SO3"
                          type="number"
                          step="0.01"
                          className={"form-control " + styles.input}
                          {...register("SO3", { min: 0, max: 100 })}
                        />
                        {errors.SO3 && errors.SO3.type === "min" && (
                          <div className={styles.error}>
                            Your SO<sub>3</sub> must be at least 0
                          </div>
                        )}
                        {errors.SO3 && errors.SO3.type === "max" && (
                          <div className={styles.error}>
                            Your SO<sub>3</sub> must be not more than 100
                          </div>
                        )}
                      </div>
                      <div className="col-12">
                        <label htmlFor="Cl" className="form-label mt-md-2">
                          <small>%Cl</small>
                        </label>
                        <input
                          id="Cl"
                          type="number"
                          step="0.01"
                          className={"form-control " + styles.input}
                          {...register("Cl", { min: 0, max: 100 })}
                        />
                        {errors.Cl && errors.Cl.type === "min" && (
                          <div className={styles.error}>
                            Your Cl must be at least 0
                          </div>
                        )}
                        {errors.Cl && errors.Cl.type === "max" && (
                          <div className={styles.error}>
                            Your Cl must be not more than 100
                          </div>
                        )}
                      </div>
                      {/* <div className="col-md-6">
                        <label htmlFor="productType" className="form-label">
                          ProductType
                        </label>
                        <select
                          className="form-select"
                          // {
                          //   styles.borderRadius0 +
                          //   " " +
                          //   styles.border1pxBlue +
                          //   " " +
                          //   styles.selectStyle +
                          //   " " +
                          //   styles.ul +
                          //   " text-left text-capitalize"
                          // }
                          {...register("ProductType", { required: true })}
                        >
                          <option value="External">External</option>
                          <option value="Internal">Internal</option>
                          <option value="YaraRega">YaraRega</option>
                        </select>
                        {errors.ProductType &&
                          errors.ProductType.type === "required" && (
                            <div className={styles.error}>This is required</div>
                          )}
                      </div> */}
                      {/* <p>{data}</p> */}
                      {/* <input type="submit" /> */}
                      {/* <DialogActions> */}
                      {/* <div className="row md-3"> */}
                      <div className="col-12 text-center pt-md-3 pb-md-3">
                        <div className="previous">
                          <Button
                            type="submit"
                            variant="contained"
                            className={styles.addPro + " btn btn-sm"}
                          >
                            Añadir producto
                          </Button>
                        </div>
                      </div>

                      <div
                        className="canc next p-0 m-0"
                        onClick={handleClickButton}
                      >
                        x
                      </div>

                      {/* </div> */}
                      {/* </DialogActions> */}
                    </form>
                  </div>
                </DialogContentText>
              </DialogContent>
              {/* <DialogActions>
              <Button onClick={handleClickButton}>Cancel</Button>
              <Button onClick={handleClickButton}>Add</Button>
            </DialogActions> */}
            </Paper>
          </div>
        </MuiPopper>
      </div>
      {/* <p>{data}</p> */}
    </div>
  );
};

export default AddProcduct;
